import React, { useState } from 'react';
import axios from 'axios';
import contactInfo from 'data/form.yml';
import contactInfoRTL from 'data/form-rtl.yml';

const Form = ({ style = "4", rtl }) => {
  const [formData, setFormdata] = useState({
    name: "",
    email: "",
    subject: "Trade Enquiry",
    message: ""
  });

  const contactInfoData = rtl ? contactInfoRTL : contactInfo;

  const handleFormChange = (e) => {
    setFormdata(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formValues = new FormData();

    formValues.append('name', formData.name);
    formValues.append('email', formData.email);
    formValues.append('subject', formData.subject);
    formValues.append('message', formData.message);

    const res = await axios.post('/api/form', formValues)
      .catch(err => alert(err.message));

    if (!res) return;

    alert('Form submitted successfully.')
  }

  return (
    <section className={`contact section-padding pt-${style === '4' ? '0':'50'} style-6`}>
      {
        style === '5' && (
          <>
            <div className="section-head text-center mb-100 style-5">
              <h2 className="mb-20">{ rtl ? 'يسعدنا' : 'Get In' } <span>{ rtl ? 'تواصلك' : 'Touch' }</span> { rtl && 'معنا' }</h2>
              <p>{ rtl ? 'سنتواصل معك مرة أخرى بعد استلام طلبك خلال 24 ساعة' : 'We will contact again after receive your request in 24h' }</p>
            </div>
            <div className="text-center mb-100">
              <h2 className="ltspc-20 text-uppercase fs-1 lh-1 mb-50 mt-30 color-blue5">{ contactInfoData.phone }</h2>
              <h4 className="fw-normal mb-20 color-000">{ contactInfoData.email }</h4>
              <h4 className="fw-normal mb-10 color-000">{ contactInfoData.address }</h4>
            </div>
          </>
        )
      }
      <div className="container">
        <div className="content">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <form action="/api/form" className="form" method="post" onSubmit={handleFormSubmit}>
                <p className="text-center text-danger fs-12px mb-30">{ rtl ? 'الحقل اللذى يحتوى على هذة العلامة اجبارى *' : 'The field is required mark as *' }</p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-20">
                      <input type="text" name="name" className="form-control" placeholder={ rtl ? 'الاسم' : "Name *" } onChange={handleFormChange} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-20">
                      <input type="email" name="email" className="form-control" placeholder={ rtl ? 'البريد الالكترونى *' : "Email Address *" } onChange={handleFormChange} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-20">
                      <select className="form-select" defaultValue={ rtl ? 'كيف يمكننا مساعدتك ؟' : "Trade Enquiry" } name="subject" onChange={handleFormChange}>
                        <option value="Trade Enquiry">{ rtl ? 'كيف يمكننا مساعدتك ؟' : 'Trade Enquiry' }</option>
                        <option value="Questions">{ rtl ? 'الاختيار الاول' : 'Questions' }</option>
                        <option value="Support Request">{ rtl ? 'الاختيار الثاني' : 'Support Request' }</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <textarea rows="10" name="message" className="form-control" placeholder={ rtl ? 'كيف يمكننا مساعدتك ؟' : "How can we help you?" } onChange={handleFormChange}></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 text-center mt-80">
                    <input type="submit" value={ rtl ? 'ارسل طلبك' : 'Send Your Request' } className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold text-light" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <img src="/assets/img/icons/contact_a.png" alt="" className="contact_a" />
          <img src="/assets/img/icons/contact_message.png" alt="" className="contact_message" />
        </div>
      </div>
    </section>
  )
}

export default Form