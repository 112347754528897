import React, {useEffect, useRef} from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from 'components/Nav/AppNav';
import Community from 'components/Community';
import Form from 'components/Form';
import Footer from 'components/Footer';
import {graphql} from "gatsby";

const PageContactApp = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef}/>
      <main className="contact-page style-5">
        <Community/>
        <Form/>
      </main>
      <Footer noWave/>
    </MainLayout>
  )
}

export const Head = ({data}) => {
  const {title} = data.site.siteMetadata;
  return (
    <>
      <title>{title} - Contact</title>
    </>
  )
}

export default PageContactApp;

export const query = graphql`
query MyQuery {
  site {
    siteMetadata {
      title
    }
  }
}
`;